<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">大气质量</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item label="时间" prop="CJSJ">
                    <el-date-picker
                        v-model="formdata.dataDic.CJSJ"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        type="datetime"
                        :disabled="!isEdit"
                        placeholder="请选择时间"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item label="CO" prop="CO_AVG_DENSITY">
                    <el-input
                        v-model="formdata.dataDic.CO_AVG_DENSITY"
                        placeholder="请输入CO"
                        @input="toDecimal($event, 'CO_AVG_DENSITY')"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                        <template slot="append">ppm</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="SO₂" prop="SO2_AVG_DENSITY">
                    <el-input
                        v-model="formdata.dataDic.SO2_AVG_DENSITY"
                        placeholder="请输入SO₂"
                        @input="toDecimal($event, 'SO2_AVG_DENSITY')"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                        <template slot="append">ppm</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="NO₂" prop="NO2_AVG_DENSITY">
                    <el-input
                        v-model="formdata.dataDic.NO2_AVG_DENSITY"
                        placeholder="请输入NO₂"
                        @input="toDecimal($event, 'NO2_AVG_DENSITY')"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                        <template slot="append">ppm</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="PM10" prop="PM10_AVG_DENSITY">
                    <el-input
                        v-model="formdata.dataDic.PM10_AVG_DENSITY"
                        placeholder="请输入PM10"
                        @input="toDecimal($event, 'PM10_AVG_DENSITY')"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                        <template slot="append">mg/m³</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="PM2.5" prop="PM25_AVG_DENSITY">
                    <el-input
                        v-model="formdata.dataDic.PM25_AVG_DENSITY"
                        placeholder="请输入PM2.5"
                        @input="toDecimal($event, 'PM25_AVG_DENSITY')"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                        <template slot="append">μg/m³</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="O₃" prop="O3_AVG_DENSITY">
                    <el-input
                        v-model="formdata.dataDic.O3_AVG_DENSITY"
                        placeholder="请输入O₃"
                        @input="toDecimal($event, 'O3_AVG_DENSITY')"
                        :maxlength="50"
                        :disabled="!isEdit"
                    >
                        <template slot="append">mg/L</template>
                    </el-input>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import infoMixin from "../../0_com_js/info_mixin.js";
export default {
    name: "zr_dqzl",
    mixins: [infoMixin],
    data() {
        return {
            isEdit: false,
            saveButton: true,
            formdata: {
                itemCode: "8020202",
                dataDic: {
                    CJSJ: "",
                    CO_AVG_DENSITY: "",
                    SO2_AVG_DENSITY: "",
                    NO2_AVG_DENSITY: "",
                    PM10_AVG_DENSITY: "",
                    PM25_AVG_DENSITY: "",
                    O3_AVG_DENSITY: "",
                },
            },
            SGLXList: [],
        };
    },
    mounted() {
        this.GetEnum();
    },
    methods: {
        ...mapActions(["getAllEnumInfobytableID"]),
        async GetEnum() {
            let res = await this.getAllEnumInfobytableID({
                bid: "1303",
            });
            let enumList = res || [];
            enumList.forEach((v) => {
                if (v.COLUMNID === "JB") {
                    this.SGLXList = v.dataItems;
                }
            });
        },
    },
};
</script>

<style>
.yearBox .yearItem {
    width: 48% !important;
}
.yearBox .separate {
    padding: 0 10px;
}
</style>
